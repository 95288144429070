import React from 'react';
import { graphql } from 'gatsby';
import rehypeReact from 'rehype-react';
import Layout from '../components/Layout';
import Sidebar from '../components/Sidebar';
import Page from '../components/Page';
import ZoomImage from '../components/ZoomImage';
import styles from './page-template.module.scss';

const renderAst = new rehypeReact({
    createElement: React.createElement,
    components: { 'zoom-image': ZoomImage },
}).Compiler;

const PageTemplate = ({ data }) => {
    const {
        title: siteTitle,
        subtitle: siteSubtitle
    } = data.site.siteMetadata;

    const {
        title: pageTitle,
        description: pageDescription
    } = data.markdownRemark.frontmatter;

    const { htmlAst: pageBody } = data.markdownRemark;

    const pageClass = pageTitle === 'Photo' ? 'page__photo' : '';

    const metaDescription = pageDescription !== null ? pageDescription : siteSubtitle;
    return (
        <Layout title={`${pageTitle} - ${siteTitle}`} description={metaDescription}>
            <Sidebar />
            <Page title={pageTitle}>
                <div className={styles[pageClass]}>{renderAst(pageBody)}</div>
            </Page>
        </Layout>
    );
};

export const query = graphql`
  query PageBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        subtitle
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      htmlAst
      frontmatter {
        title
        date
        description
      }
    }
  }
`;

export default PageTemplate;
