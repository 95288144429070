import React from 'react';
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';
import styles from './ZoomImage.module.scss';

export default class ZoomImage extends React.Component {
    render() {
        return (
            <figure className={styles['zoomImage']}>
                <Zoom zoomMargin={15}>
                    <img
                        src={this.props.src}
                        alt={this.props.alt || this.props.caption}
                        className={styles['zoomImage__image']}
                    />
                </Zoom>
                {this.props.caption && (
                    <figcaption className={styles['zoomImage__caption']}>{this.props.caption}</figcaption>
                )}
            </figure>
        );
    }
}
